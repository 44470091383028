import React, { Component } from 'react';
import icon1 from './media/icon1.png';
import icon2 from './media/icon2.png';
import icon3 from './media/icon3.png';
import icon4 from './media/icon4.png';
import arrowhw from './media/arrowhw.png';
import i18n from "i18next";
import Regioni from './Regioni';
import TopResults from './TopResults';
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';
import location from './media/locationcta.png';
import { BoxLoading } from 'react-loadingg';

export class Home extends Component {


    constructor(props){
        super(props);
        this.state = { showCallToAction: false, contactemail: '', callToActionSuccess: false };
    }


    showCreateCourse = () => {
        this.props.create();
    }

    callToActionCompany = () => {
        this.setState({ showCallToAction: true, contactemail: '', callToActionSuccess: false });
    }

    closeCallToAction = () => {
        this.setState({ showCallToAction: false });
    }

    insertText = (e) => {
        var insert = String(e.target.value);   
        var fieldName = e.target.name;  
        this.setState({ [fieldName]: insert });     
    }

    getBrochure = () => {
        if(this.state.contactemail.length<5){
            return;
        }
        this.setState({ openLoader: true });
        var sessiontoken = localStorage.getItem('session_token');
          const requestOptions = {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify({
              token: sessiontoken,
              email: this.state.contactemail
            })
            };
          
            const apiUrl = 'https://italiagourmet.cloud/api/public/v1/contact/';
      
          fetch(apiUrl, requestOptions)
          .then(r => r.json())
          .then(r => {
           
            this.setState({ openLoader: false, callToActionSuccess: true });
           
            
          }).catch(e => {
            this.setState({ openLoader: false });
          });
    }


    render() {
        return (
            <div>

        <TopResults />


                 
        <div style={{textAlign: 'center', marginBottom: '20px', marginLeft: '40px', marginRight: '40px'}}><span style={{color: 'black', fontSize: 26, fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('title.home')} </span></div>
      
        
        
      <div style={{ display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
    flexWrap: 'wrap',
    flexShrink: 1}}>

<div style={{width: '200px', height: '200px', position: 'relative', margin: '15px' }}>
    <div style={{width: 200, height: 200, left: 0, top: 0, position: 'absolute', background: 'rgb(250,250,250)', borderRadius: 25}} />
    <div style={{width: 180, height: 25, left: 10, top: 90, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 18,   fontWeight: '800', wordWrap: 'break-word'}}>{i18n.t('title.1')}</div>
    <div style={{width: 180, height: 50, left: 10, top: 130, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 14,   fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('title.1.desc')}</div>
    <div style={{width: 70, height: 70, left: 65, top: 15, position: 'absolute'}}>
    <img src={icon4} width="60px" />
    </div>
</div>

<div style={{width: '200px', height: '200px', position: 'relative', margin: '15px' }}>
    <div style={{width: 200, height: 200, left: 0, top: 0, position: 'absolute', background: 'rgb(250,250,250)', borderRadius: 20}} />
    <div style={{width: 180, height: 25, left: 10, top: 90, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 18,   fontWeight: '800', wordWrap: 'break-word'}}>{i18n.t('title.2')}</div>
    <div style={{width: 180, height: 50, left: 10, top: 130, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 14,   fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('title.2.desc')}</div>
    <div style={{width: 70, height: 70, left: 65, top: 15, position: 'absolute'}}>
    <img src={icon3} width="60px" />
    </div>
</div>

<div style={{width: '200px', height: '200px', position: 'relative', margin: '15px' }}>
    <div style={{width: 200, height: 200, left: 0, top: 0, position: 'absolute', background: 'rgb(250,250,250)', borderRadius: 40}} />
    <div style={{width: 180, height: 25, left: 10, top: 90, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 18,   fontWeight: '800', wordWrap: 'break-word'}}>{i18n.t('title.3')}</div>
    <div style={{width: 180, height: 50, left: 10, top: 130, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 14,   fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('title.3.desc')}</div>
    <div style={{width: 70, height: 70, left: 65, top: 15, position: 'absolute'}}>
    <img src={icon1} width="60px" />
    </div>
</div>

<div style={{width: '200px', height: '200px', position: 'relative', margin: '15px' }}>
    <div style={{width: 200, height: 200, left: 0, top: 0, position: 'absolute', background: 'rgb(250,250,250)', borderRadius: 40}} />
    <div style={{width: 180, height: 25, left: 10, top: 90, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 18,   fontWeight: '800', wordWrap: 'break-word'}}>{i18n.t('title.4')}</div>
    <div style={{width: 180, height: 50, left: 10, top: 130, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 14,   fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('title.4.desc')}</div>
    <div style={{width: 70, height: 70, left: 65, top: 15, position: 'absolute'}}>
    <img src={icon2} width="60px" />
    </div>
</div>
</div>

<br /><br /><br />


<div style={{ display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
    flexWrap: 'wrap',
    flexShrink: 1}}>
    <div style={{width: '300px', height: '300px', position: 'relative'}}>
    <div style={{width: 300, height: 300, left: 0, top: 0, position: 'absolute'}} />
    <div style={{width: 250, height: 40, left: 25, top: 30, position: 'absolute', color: 'black', fontSize: 20,   fontWeight: '800',  wordWrap: 'break-word'}}>{i18n.t('how.works')}</div>
    <div style={{width: 250, height: 80, left: 25, top: 78, position: 'absolute', color: 'black', fontSize: 16,   fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('how.works.desc')}</div>
    <div style={{width: 40, height: 40, left: 70, top: 150, position: 'absolute'}}>
    <img src={arrowhw} width="150px" />
    </div>
   </div>

   <div style={{width: '300px', height: '300px', position: 'relative'}}>
   <div style={{width: '300px', height: '100px', position: 'relative'}}>
    <div style={{width: 300, height: 100, left: 0, top: 0, position: 'absolute'}} />
    <div style={{width: 50, height: 50, left: 0, top: 0, position: 'absolute', textAlign: 'center', color: '#E82E15', fontSize: 32,   fontWeight: '800', wordWrap: 'break-word'}}>1</div>
    <div style={{width: 230, height: 69, left: 50, top: 21, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 16,   fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('how.works.1')}</div>
    </div>

    <div style={{width: '300px', height: '100px', position: 'relative'}}>
    <div style={{width: 300, height: 100, left: 0, top: 0, position: 'absolute'}} />
    <div style={{width: 50, height: 50, left: 250, top: 0, position: 'absolute', textAlign: 'center', color: '#E82E15', fontSize: 32,   fontWeight: '800', wordWrap: 'break-word'}}>2</div>
    <div style={{width: 230, height: 69, left: 20, top: 21, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 16,   fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('how.works.2')}</div>
    </div>

    <div style={{width: '300px', height: '100px', position: 'relative'}}>
    <div style={{width: 300, height: 100, left: 0, top: 0, position: 'absolute'}} />
    <div style={{width: 50, height: 50, left: 0, top: 0, position: 'absolute', textAlign: 'center', color: '#E82E15', fontSize: 32,   fontWeight: '800', wordWrap: 'break-word'}}>3</div>
    <div style={{width: 230, height: 69, left: 50, top: 21, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 16,   fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('how.works.3')}</div>
    </div>
   </div>

</div>

<br />

<div  style={{marginTop: '0px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: '#E82E15', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.callToActionCompany}
            >{i18n.t('call.to.action.company')}</p>
                    </div>

<br />

    <Regioni />

<br />
<br />


<Drawer
                    open={this.state.showCallToAction}
                    onRequestClose={this.closeCallToAction}
                        modalElementClass="modalMobileLogin2">
                         <div style={{textAlign: 'center', margin: '15px'}}>
                         <img src={location} width="120px" style={{ marginTop: '-80px' }} />

                         {this.state.callToActionSuccess &&
                            <div>
                         <div  style={{marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '16px', fontWeight: '500' }}>
                        {i18n.t('call.to.action.success')}
                        </div>
                        <br />
                        </div>
                        }

                        {!this.state.callToActionSuccess &&
                         <div>
                         <div  style={{marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '16px', fontWeight: '500' }}>
                        {i18n.t('call.to.action.company')}
                        </div>

                        <div  style={{marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '14px', fontWeight: '400' }}>
                        {i18n.t('call.to.action.company.description')}
                        </div>
                       
                      
                        <div  style={{marginTop: '30px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: 'rgb(50,50,50)', fontWeight: 'normal', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgb(250,250,250)', border: 'none', outlineWidth: '0', fontSize: this.state.componentFontSize, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text"  
                name="contactemail"
                value={this.state.contactemail}
                placeholder={i18n.t('email')} 
                onChange={this.insertText}
                
                />
               </div>
                        <div  style={{marginTop: '0px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: '#E82E15', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.getBrochure}
            >{i18n.t('get.brochure')}</p>
                    </div>
                    </div>
                    }

                    <div onClick={this.closeCallToAction} style={{  textAlign: 'center', marginLeft: '20px', marginRight: '20px', marginBottom: '10px'}}><span style={{cursor: 'pointer', color: 'black', fontSize: 12, fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('close')}</span></div>
   
                    </div>
                    </Drawer>

                    <Drawer
                    open={this.state.openLoader}
                        modalElementClass="modalLoader">
                         <div style={{textAlign: 'center', margin: '15px'}}>
            
                         <BoxLoading size="large" color="#E82E15" />
                    </div>
                    </Drawer>
            </div>
        )
    }
}

export default Home
