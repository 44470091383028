import React, { Component } from 'react';
import i18n from "i18next";
import noproducts from './media/noproducts.png';
import orderok from './media/orderok.png';
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';
import { BoxLoading } from 'react-loadingg';

export class Cart extends Component {


    constructor(props){
        super(props);
        this.state = { products: props.products, openLoader: false, showCustomerField: false, logged: props.logged,
            customerName: '', customerEmail: '', customerCountry: '', customerAddress: '', totalCart: props.totalCart,
            errorField: false, errorOrder: false, showSuccess: false  };
        
    }

    componentDidMount () {
      
       
    }

    static getDerivedStateFromProps(props, current_state) {
        if(current_state.products !== props.products){
            return {
                products: props.products,
                totalCart: props.totalCart,
                logged: props.logged
              }
        }
        
        
        return null;
    }

      openAddress = () => {
          //https://www.google.com/maps/place/Via+Torino,+4,+20123+Milano+MI
          
          let newLocation = "https://www.google.com/maps/place/"+this.state.business.address;
          window.open(newLocation, "_blank");
      }

      openWebSite = () => {
        window.open(this.state.business.website, "_blank");
      }

      showProducts = () => {
        this.setState({ currentSection: 0 });
      }

      showBusinessInfo = () => {
        this.setState({ currentSection: 1 });
      }

      openProduct = (index) => {
        this.setState({ showProductInfo: true, currentProduct: index, currentQuantity: 1 });
      }

      closeProductInfo = () => {
        this.setState({ showProductInfo: false });
      }

      addQuantity = (index) => {
        this.setState({ openLoader: true });
        var sessiontoken = localStorage.getItem('session_token');
        const requestOptions = {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify({
            token: sessiontoken,
            product: this.state.products[index].id,
            quantity: '1'
          })
          };
        
          const apiUrl = 'https://italiagourmet.cloud/api/public/v1/cart/add';
    
        fetch(apiUrl, requestOptions)
        .then(r => r.json())
        .then(r => {
         this.props.reloadCart();
         this.setState({ openLoader: false });
        }).catch(e => {
           
        });
      }

      removeQuantity = (index) => {
        this.setState({ openLoader: true });
        var sessiontoken = localStorage.getItem('session_token');
        const requestOptions = {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify({
            token: sessiontoken,
            product: this.state.products[index].id,
            quantity: '1'
          })
          };
        
          const apiUrl = 'https://italiagourmet.cloud/api/public/v1/cart/remove';
    
        fetch(apiUrl, requestOptions)
        .then(r => r.json())
        .then(r => {
         this.props.reloadCart();
         this.setState({ openLoader: false });
        }).catch(e => {
           
        });
      }

      addProduct = () => {
        var sessiontoken = localStorage.getItem('session_token');
        const requestOptions = {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify({
            token: sessiontoken,
            product: this.state.products[this.state.currentProduct].id,
            quantity: this.state.currentQuantity
          })
          };
        
          const apiUrl = 'https://italiagourmet.cloud/api/public/v1/cart/add';
    
        fetch(apiUrl, requestOptions)
        .then(r => r.json())
        .then(r => {
         this.closeProductInfo();
         this.props.reloadCart();
        }).catch(e => {
           
        });
      }


      sendOrder = () => {
        this.setState({ errorField: false, errorOrder: false });
    if(!(this.state.customerName.length>3 && this.state.customerEmail.length>3 && this.state.customerAddress.length>3 && this.state.customerCountry.length>2)){
      this.setState({ errorField: true });
      return;
    }
    this.setState({ openLoader: true });
    var sessiontoken = localStorage.getItem('session_token');
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify({
        token: sessiontoken,
        name: this.state.customerName,
        email: this.state.customerEmail,
        country: this.state.customerCountry,
        address: this.state.customerAddress,
      })
      };
    
      const apiUrl = 'https://italiagourmet.cloud/api/public/v1/cart/send';

    fetch(apiUrl, requestOptions)
    .then(r => r.json())
    .then(r => {
     if(r.order == false){
        this.setState({ errorOrder: true });
     }else{
       this.setState({ showSuccess: true });

     } 
     
     this.setState({ openLoader: false });
    }).catch(e => {
        this.setState({ openLoader: false, errorOrder: true });
      
    });
      }


      conitnueToOrder = () => {
        this.setState({ openLoader: true });
    var sessiontoken = localStorage.getItem('session_token');
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify({
        token: sessiontoken,
      })
      };
    
      const apiUrl = 'https://italiagourmet.cloud/api/public/v1/profile/details';

    fetch(apiUrl, requestOptions)
    .then(r => r.json())
    .then(r => {
       
      if(r.logged == true){
        if(r.user_info.subscription_plan == 1){
          this.setState({ showCustomerField: true, errorField: false, errorOrder: false });
        }else{
          this.props.subscribe();
        }
      }else{
        this.props.openLogin();
      }
    
     
     this.setState({ openLoader: false });

    }).catch(e => {
        this.setState({ openLoader: false });
      
    });
      }

   

      insertText = (e) => {
        this.setState({ errorField: false, errorOrder: false });
        var insert = String(e.target.value);   
        var fieldName = e.target.name;  
        this.setState({ [fieldName]: insert });     
    }

    closeOrderSuccessMessage = () => {
        var newLocation = "/";
        window.location.href = newLocation;
        return;
    }
  

    render() {
        return (
            <div style={{  }}>
              {(this.state.products.length == 0) &&
                <div><br /><br />{i18n.t('empty.cart')}<br/><br/>
<img src={noproducts} width="35px" height="35px" style={{ borderRadius: '0px'}} /></div>
                }

{(this.state.products.length != 0 && !this.state.showCustomerField) &&
<div>
<div style={{ fontSize: 18,   fontWeight: '600' }}><br />{i18n.t('your.cart')}<br /><br /></div><div style={{ display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "stretch",
  flexWrap: 'wrap',
  flexShrink: 1,}}>
   <div style={{margin: '10px'}}>
 <div style={{ margin: 'auto', borderRadius: '8px', width: '350px', backgroundColor: 'rgb(250,250,250)', display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",}}>
    
  {
        this.state.products.map((product, i) =>
<div key={i} onClick={() => this.openProduct(i)}  style={{ borderRadius: '8px', width: '330px', minHeight: '70px', margin: '10px', backgroundColor: 'white', position: 'relative' }}>
        <div style={{ display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "stretch",
  flexWrap: 'wrap',
  flexShrink: 1,}}>
<div style={{ width: 100, height: 70, left: 0, top: 0}}>
 <img src={product.image} width="100px" height="70" style={{ objectFit: 'cover', borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' }} />
 </div>
 <div style={{}}>
 <div style={{width: 140,  marginLeft: '5px', marginTop: '5px',   textAlign: 'left', color: 'rgb(50,50,50)', fontSize: 12,  fontWeight: '500', wordWrap: 'break-word'}}>{product.name}</div>
 <div style={{width: 140,  marginLeft: '5px', marginTop: '5px',   textAlign: 'left', color: 'rgb(50,50,50)', fontSize: 12,  fontWeight: '400', wordWrap: 'break-word'}}>{product.business_name}</div>
</div>
<div style={{ width: 80, backgroundColor: 'white' }}>
                <div style={{ marginTop: '10px', marginLeft: '5px', width: 80, display: 'flex', flexDirection: 'row'}}>
                <div style={{ color: 'rgb(100,100,100)', fontSize: '18px', cursor: 'pointer'}} onClick={() => this.removeQuantity(i)}>-</div>
                <div style={{ marginLeft: '20px', marginRight: '20px', color: 'black', fontSize: '16px'}}>{product.quantity}</div>
                <div style={{ color: 'rgb(100,100,100)', fontSize: '18px', cursor: 'pointer'}} onClick={() => this.addQuantity(i)}>+</div>
                </div> 
                <div style={{width: 80, top: 5, marginTop: '10px',  textAlign: 'center', color: 'rgb(50,50,50)', fontSize: 14,  fontWeight: '600', wordWrap: 'break-word'}}>{(Math.round(product.price*product.quantity) / 100).toFixed(2)}€</div>

                </div> 
 </div>
            

</div>
        )
 }
  
  </div>
  </div>
  <div style={{  margin: '10px', borderRadius: '8px',  width: '350px', height: '150px', backgroundColor: 'rgb(250,250,250)' }}>
    <br/>
  <div style={{ display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "stretch",
  flexWrap: 'wrap',
  flexShrink: 1,}}>
 <div style={{ top: 15, marginTop: '20px', margin: '10px',  textAlign: 'center', color: 'rgb(50,50,50)', fontSize: 16,  fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('subtotal')}</div>
 <div style={{ top: 15, marginTop: '20px', margin: '10px',  textAlign: 'center', color: 'rgb(50,50,50)', fontSize: 16,  fontWeight: '600', wordWrap: 'break-word'}}>{(Math.round(this.state.totalCart) / 100).toFixed(2)}€</div>
 </div>

 <div style={{ margin: '0px',  textAlign: 'center', color: 'rgb(50,50,50)', fontSize: 12,  fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('except.delivery')}</div>
 <div  style={{marginTop: '5px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: '#E82E15', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.conitnueToOrder}
            >{i18n.t('continue')}</p>
                    </div>
                    
  </div>
  
  </div>
 </div>
}

{(this.state.products.length != 0 && this.state.showCustomerField) &&
    <div><div style={{ fontSize: 18,   fontWeight: '600' }}><br />{i18n.t('continue.order')}</div>
<div style={{ display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "stretch",
  flexWrap: 'wrap',
  flexShrink: 1,}}>
<div style={{  margin: '30px', borderRadius: '8px',  width: '350px', backgroundColor: 'rgb(250,250,250)' }}>
    <br/>
  <div style={{ display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "stretch",
  flexWrap: 'wrap',
  flexShrink: 1,}}>
 <div style={{ marginTop: '0px', textAlign: 'center', color: 'rgb(50,50,50)', fontSize: 16,  fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('customer.data')}</div>
 </div>
                
 <div  style={{marginTop: '15px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: 'rgb(50,50,50)', fontWeight: 'normal', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'white', border: 'none', outlineWidth: '0', fontSize: this.state.componentFontSize, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text"  
                name="customerName"
                value={this.state.customerName}
                placeholder={i18n.t('name.surname')} 
                onChange={this.insertText}
                />
               </div>

               <div  style={{marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: 'rgb(50,50,50)', fontWeight: 'normal', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'white', border: 'none', outlineWidth: '0', fontSize: this.state.componentFontSize, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text"  
                name="customerEmail"
                value={this.state.customerEmail}
                placeholder={i18n.t('email')} 
                onChange={this.insertText}
                />
               </div>

               <div  style={{marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: 'rgb(50,50,50)', fontWeight: 'normal', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'white', border: 'none', outlineWidth: '0', fontSize: this.state.componentFontSize, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text"  
                name="customerCountry"
                value={this.state.customerCountry}
                placeholder={i18n.t('customer.country')} 
                onChange={this.insertText}
                />
               </div>

               <div  style={{marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: 'rgb(50,50,50)', fontWeight: 'normal', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'white', border: 'none', outlineWidth: '0', fontSize: this.state.componentFontSize, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text"  
                name="customerAddress"
                value={this.state.customerAddress}
                placeholder={i18n.t('customer.address')} 
                onChange={this.insertText}
                />
               </div>

               {this.state.errorField &&
            <div style={{marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <br/><br/>
            <span style={{color: 'red', fontSize: 12, fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('error.field')}</span></div>
            } 
            
            {this.state.errorOrder &&
            <div style={{marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <br/><br/>
            <span style={{color: 'red', fontSize: 12, fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('error.order')}</span></div>
            } 

               <div style={{  marginTop: '25px',  textAlign: 'center', color: 'rgb(50,50,50)', fontSize: 16,  fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('subtotal')}</div>
 <div style={{  margin: '10px',  textAlign: 'center', color: 'rgb(50,50,50)', fontSize: 16,  fontWeight: '600', wordWrap: 'break-word'}}>{(Math.round(this.state.totalCart) / 100).toFixed(2)}€</div>


 <div style={{ margin: '0px',  textAlign: 'center', color: 'rgb(50,50,50)', fontSize: 12,  fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('except.delivery')}</div>
 <div  style={{marginTop: '0px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: '#E82E15', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.sendOrder}
            >{i18n.t('send.order')}</p>
                    </div>                 
  </div>
  </div>
  </div>
}


<br /><br /><br /><br />

<Drawer
                    open={this.state.openLoader}
                        modalElementClass="modalLoader">
                         <div style={{textAlign: 'center', margin: '15px'}}>
            
                         <BoxLoading size="large" color="#E82E15" />
                    </div>
                    </Drawer>

                    <Drawer
                    open={this.state.showSuccess}
                        modalElementClass="modalMobileLogin2"
                            onRequestClose={this.closeOrderSuccessMessage}>
                                  <div style={{textAlign: 'center'}}>
                         <div style={{textAlign: 'center', margin: '20px'}}>
            <span style={{color: 'rgb(50,50,50)', fontSize: 20, fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('order.success')}</span></div>
           
                    <img src={orderok} width="45px" height="45px" style={{ borderRadius: '0px'}} />
                    <div style={{textAlign: 'center', margin: '20px'}}>
            <span style={{color: 'rgb(50,50,50)', fontSize: 16, fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('order.success.message')}</span></div>
            <div onClick={this.closeOrderSuccessMessage} style={{  textAlign: 'center', marginLeft: '20px', marginRight: '20px', marginBottom: '10px'}}><span style={{cursor: 'pointer', color: 'black', fontSize: 12, fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('close')}</span></div>
   
                    </div>
                    </Drawer>

            </div>
        )
    }
}

export default Cart
/*{
        this.state.products.map((product, i) =>
<div key={i} onClick={() => this.openProduct(i)}  style={{ borderRadius: '8px', width: '250px', minHeight: '100px', margin: '10px', backgroundColor: 'white', position: 'relative', cursor: 'pointer' }}>
        <div style={{ display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "stretch",
  flexWrap: 'wrap',
  flexShrink: 1,}}>
<div style={{ width: 150, height: 80, left: 0, top: 0}}>
 <img src={product.image} width="150px" height="80" style={{ objectFit: 'cover', borderTopLeftRadius: '8px' }} />
 </div>
 <div>
                <div style={{ marginTop: '5px', width: '100px', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <div style={{ color: 'rgb(100,100,100)', fontSize: '24px', cursor: 'pointer'}} onClick={() => this.removeQuantity(i)}>-</div>
                <div style={{ marginLeft: '20px', marginRight: '20px', color: 'black', fontSize: '22px'}}>{product.quantity}</div>
                <div style={{ color: 'rgb(100,100,100)', fontSize: '24px', cursor: 'pointer'}} onClick={() => this.addQuantity(i)}>+</div>
                </div>
                
 <div style={{width: 100, top: 5, marginTop: '15px',  textAlign: 'center', color: 'rgb(100,100,100)', fontSize: 14,  fontWeight: '600', wordWrap: 'break-word'}}>{(Math.round(product.price*product.quantity) / 100).toFixed(2)}€</div>
</div>
 </div>
 <div style={{width: 250,  margin: '5px', marginTop: '10px', marginBlock: '10px',  textAlign: 'left', color: 'rgb(50,50,50)', fontSize: 14,  fontWeight: '500', wordWrap: 'break-word'}}>{product.name}</div>
 <div style={{width: 250,  margin: '5px', marginTop: '10px', marginBlock: '10px',  textAlign: 'left', color: 'rgb(50,50,50)', fontSize: 14,  fontWeight: '400', wordWrap: 'break-word'}}>{product.business_name}</div>
               

</div>
        )
 }
*/