import React, { Component } from 'react';
import i18n from "i18next";

export class Footer extends Component {


    constructor(props){
        super(props);
    }

    componentDidMount () {
    
      
       
    }

  
    

    render() {
        return (
            <div style={{  position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', backgroundColor: 'rgb(250,250,250)', height: '100px' }}>
               
               <br />
               <span  style={{ top: '20px',color: 'black', fontSize: 16,   fontWeight: '800', wordWrap: 'break-word'}}>
               Italia Gourmet</span>
                <br />
               <span  style={{ top: '20px',color: 'black', fontSize: 12,   fontWeight: '400', wordWrap: 'break-word'}}>
               Le eccelenze italiane dai migliori produttori italiani, selezionate per te.</span>
               <br /><br />
               <span  style={{ left: 0, color: 'black', fontSize: 10,   fontWeight: '600', wordWrap: 'break-word'}}>
               Copyright © 2024 - Italia Gourmet</span>
            </div>
        )
    }
}

export default Footer
