import React, { Component } from 'react';
import maskregion from './media/maskregion.png';
import i18n from "i18next";

export class Regioni extends Component {


    constructor(props){
        super(props);
        this.state = { regions: []};
    }

    componentDidMount () {
    
        var sessiontoken = localStorage.getItem('session_token');
       
        if(sessiontoken != null){
            this.getRegionsList(sessiontoken);
               
          return;
        }
       
    }

    getRegionsList = (token) => {
  
        const requestOptions = {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify({
            token: token
          })
          };
        
          const apiUrl = 'https://italiagourmet.cloud/api/public/v1/region/list';
    
        fetch(apiUrl, requestOptions)
        .then(r => r.json())
        .then(r => {
         
          this.setState({ regions: r.regions });
        }).catch(e => {
           
        });
    
      }

    openRegionInfo = (code) => {
        var newLocation = "/regione/"+code;
        window.location.href = newLocation;
        return;
    }
    

    render() {
        return (
            <div>
                 <br />
       
        
      <div style={{ display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
    flexWrap: 'wrap',
    flexShrink: 1}}>
 {
           this.state.regions.map((region, i) =>
<div key={i} onClick={() => this.openRegionInfo(region.code)}  style={{width: '200px', height: '100px', position: 'relative', margin: '10px', cursor: 'pointer' }}>
           <div style={{width: 200, height: 100, left: 0, top: 0, position: 'absolute'}}>
    <img src={region.image} width="200px" height="100" style={{ borderRadius: '8px'}} />
    </div>
    <div style={{width: 200, height: 100, left: 0, top: 0, position: 'absolute'}}>
    <img src={maskregion} width="200px" height="100" style={{ borderRadius: '8px'}} />

    <div style={{width: 180, height: 25, left: 10, top: 75, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 18,   fontWeight: '800', wordWrap: 'break-word'}}>{region.name}</div>
    </div>
</div>
           )
    }

</div>

<br /><br />


            </div>
        )
    }
}

export default Regioni
