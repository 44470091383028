import './App.css';
import { Component } from 'react';
import Home from './Home';
import i18n from "i18next";
import logo from './media/logo.png';
import cart from './media/cart.png';
import rocket from './media/rocket.png';
import Regione from './Regione';
import Footer from './Footer';
import Azienda from './Azienda';
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';
import Cart from './Cart';
import Profile from './Profile';
import { BoxLoading } from 'react-loadingg';

class App extends Component {
  
  intervalID = 0;

  constructor(props){
    super(props);
    
    
    var pageCode = window.location.href.replace(window.location.origin, "");
    pageCode = pageCode.replace("/", "");   
  
    
    this.state = {showHome: false, showRegione: false, showBusiness: false, showCart: false, showProfile: false,
      pageCode: pageCode, isReady: false, logged: false, showLogin: false, showRegister: false, showRecoverPassword: false,
      cart: [], animationShake: '', errorField: false, errorMail: false, errorLogin: false, recoverSuccess: false,
      email: '', password: '', name: '', surname: '', openSubscription: false, promocode: '', openLoader: false };
        
  }

  componentDidMount () {
    
      var sessiontoken = localStorage.getItem('session_token');
     
      if(sessiontoken === null){     
        this.createToken();
        return;
      }else{
        this.checkToken(sessiontoken);
        return;
      }
  }

  showRecoverPassword = () => {
    this.setState({ showLogin: false, showRecoverPassword: true, recoverSuccess: false });
  }

  openSubscribeNow = () => {
    this.setState({ openSubscription: true, promocode: '' });
  }

  recoverPassword = () => {
    this.setState({ errorField: false, errorLogin: false, errorMail: false });
    if(!(this.state.email.length>3)){
      this.setState({ errorField: true });
      return;
    }

    var sessiontoken = localStorage.getItem('session_token');
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify({
        token: sessiontoken,
        email: this.state.email,
      })
      };
    
      const apiUrl = 'https://italiagourmet.cloud/api/public/v1/profile/recoverpassword';

    fetch(apiUrl, requestOptions)
    .then(r => r.json())
    .then(r => {
     if(r.recover == false){
        this.setState({ errorMail: true });
     }else{
      this.setState({ recoverSuccess: true });
     } 
     
      
    }).catch(e => {
      this.closeLogin();
      this.getProfileInfo();
    });
  }
  

  showHome = () => {
   if(this.state.pageCode != ''){
    window.location.href = window.location.origin;
    return;
   }
    this.setState({ currentSection: 0, showHome: true, showCart: false, showProfile: false });
  }

  

  firstLoad = () => {
    if(this.state.pageCode == ''){
      this.showHome();
    }else{
      if(this.state.pageCode.includes('regione/')){
        let regionCode = this.state.pageCode.replace("regione/", "");  
        this.setState({ showHome: false, showRegione: true, showProfile: false, showBusiness: false, regionCode: regionCode, isReady: true, showCart: false }); 
     
      }else if(this.state.pageCode.includes('azienda/')){
        let businessCode = this.state.pageCode.replace("azienda/", "");  
        this.setState({ showHome: false, showRegione: false, showProfile: false, showBusiness: true, businessCode: businessCode, isReady: true, showCart: false }); 
     
      }else if(this.state.pageCode.includes('cart')){
        this.setState({ showCart: true, showProfile: false, showHome: false, showBusiness: false, showRegione: false, showLogin: false });
      }    
    }
    this.getProfileInfo();
  }


  checkToken = (token) => {
    
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify({
        token: token
      })
      };
    
      const apiUrl = 'https://italiagourmet.cloud/api/public/v1/hello/check';

    fetch(apiUrl, requestOptions)
    .then(r => r.json())
    .then(r => {
      this.firstLoad();
      
    }).catch(e => {
        localStorage.removeItem('session_token');
        window.location.reload();
    });

  }

  createToken = () => {
   
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      })
      };
    
      const apiUrl = 'https://italiagourmet.cloud/api/public/v1/hello/client';

    fetch(apiUrl, requestOptions)
    .then(r => r.json())
    .then(r => {
      
      localStorage.setItem('session_token', r.token);
      this.checkToken(r.token);
    }).catch(e => {
      
       //localStorage.removeItem('session_token');
       // window.location.reload();
    });

  }

 
  changeSection = (event) => {
    if(event.value == '0'){
      this.showHome();
    }else if(event.value == '1'){
      this.showMyCourses();
    }else if(event.value == '2'){
      this.showSubscriptionPage();
    }else if(event.value == '3'){
      this.logoutNow();
    }
  }

  sendMail = () => {
   
  }

  closeAnimation = (e) => {
    this.setState({  animationShake: '', animationShake1: '', animationShake2: ''}); 
    clearInterval(this.intervalID);
}

checkboxAction = (e) => {
  var checkBox = e.target.name;
  if(checkBox === "ck1"){
      if(this.state.ck1){
          this.setState({ ck1: false}); 
      }else{
          this.setState({ ck1: true}); 
      }
  }else if(checkBox === "ck2"){
      if(this.state.ck2){
          this.setState({ ck2: false}); 
      }else{
          this.setState({ ck2: true}); 
      }
  }
  
}


  registerNow = () => {
    this.setState({ errorField: false, errorLogin: false, errorMail: false });
    if(!(this.state.email.length>3 && this.state.password.length>5 && this.state.name.length>2 && this.state.surname.length>2)){
      this.setState({ errorField: true });
      return;
    }

    if(!this.state.ck2){
      this.setState({ animationShake2: 'animationShake'}); 
      this.intervalID = setInterval(this.closeAnimation, 300);
      return;
  }

  

  if(!this.state.ck1){
      this.setState({ animationShake1: 'animationShake'}); 
      this.intervalID = setInterval(this.closeAnimation, 300);
      return;
  }

    var sessiontoken = localStorage.getItem('session_token');
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify({
        token: sessiontoken,
        name: this.state.name,
        surname: this.state.surname,
        email: this.state.email,
        password: this.state.password
      })
      };
    
      const apiUrl = 'https://italiagourmet.cloud/api/public/v1/profile/register';

    fetch(apiUrl, requestOptions)
    .then(r => r.json())
    .then(r => {
     if(r.register == false){
        this.setState({ errorMail: true });
     }else{
      this.closeLogin();
      this.getProfileInfo();
     } 
     
      
    }).catch(e => {
      this.closeLogin();
      this.getProfileInfo();
    });
  }

  clickOnMyProfile = () => {
    if(!this.state.logged){
      this.closeLogin();
      this.setState({ showLogin: true,  errorField: false, errorLogin: false, errorMail: false, email: '', password: '' });
    }else{
      this.setState({ showCart: false, showProfile: true, showHome: false, showBusiness: false, showRegione: false, showLogin: false });
    }
  }

 


  getProfileInfo = () => {
    var sessiontoken = localStorage.getItem('session_token');
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify({
        token: sessiontoken
      })
      };
    
      const apiUrl = 'https://italiagourmet.cloud/api/public/v1/profile/info';

    fetch(apiUrl, requestOptions)
    .then(r => r.json())
    .then(r => {
      
      var totalCart = this.totalCartPrice(r.cart);
      this.setState({  logged: r.logged, cart: r.cart, totalCart: totalCart, isReady: true });
      
    }).catch(e => {
        
    });
  }

  totalCartPrice = (cart) => {
    var total = 0;
    for(var i=0; i<cart.length; i++){
      total += (cart[i].quantity*cart[i].price);
    }
    return total;
  }

  reloadCart = () => {
    this.getProfileInfo();
    this.setState({ animationShake: 'animationShake'});
    this.intervalID = setInterval(this.closeAnimation, 300);
  }

 

  showCart = () => {
      var newLocation = "/cart";
      window.location.href = newLocation;
      return;
  }

closeLogin = () => {
  this.setState({ showLogin: false, showRegister: false, showRecoverPassword: false, errorField: false, errorLogin: false, errorMail: false });
}

showRegister = () => {
  this.setState({ showLogin: false, showRegister: true,  errorField: false, errorLogin: false, errorMail: false, email: '', password: '', name: '', surname: '' });
}



loginNow = () => {
    this.setState({ errorField: false, errorLogin: false, errorMail: false });
    if(!(this.state.email.length>3 && this.state.password.length>5)){
      this.setState({ errorField: true });
      return;
    }
  
    var sessiontoken = localStorage.getItem('session_token');
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify({
        token: sessiontoken,
        email: this.state.email,
        password: this.state.password
      })
      };
    
      const apiUrl = 'https://italiagourmet.cloud/api/public/v1/profile/login';

    fetch(apiUrl, requestOptions)
    .then(r => r.json())
    .then(r => {
     if(r.login == false){
      this.setState({ errorLogin: true });
     }else{
      this.closeLogin();
      this.getProfileInfo();
     } 
     
      
    }).catch(e => {
      this.closeLogin();
      this.getProfileInfo();
    });

}

insertText = (e) => {
  this.setState({ errorField: false, errorLogin: false, errorMail: false });
  var insert = String(e.target.value);   
  var fieldName = e.target.name;  
  this.setState({ [fieldName]: insert });     
}


closeSubscribeNow = () => {
  this.setState({ openSubscription: false });
}


getPurchaseLink = () => {
  this.setState({ openLoader: true });
  var sessiontoken = localStorage.getItem('session_token');
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify({
        token: sessiontoken,
        code: this.state.promocode
      })
      };
    
      const apiUrl = 'https://italiagourmet.cloud/api/public/v1/profile/subscribe';

    fetch(apiUrl, requestOptions)
    .then(r => r.json())
    .then(r => {
     
      window.location.href = r.payment;
      return;
     
      
    }).catch(e => {
      this.setState({ openLoader: false });
    });
}

  render(){
    
    return (
     
    <div className='App' style={{ backgroundColor: 'white'}}>
      
   <div style={{ top: 0, width: '100%', height: '50px', position: 'sticky', backgroundColor: 'white', zIndex: 1000 }}>
   <div style={{ width: '100%', top: '49px', height: '1px', backgroundColor: '#F2F1F9', position: 'absolute' }}></div>
  
   
        <div  style={{ display: "flex",position: 'absolute', right: '1px' }}>
        <div onClick={this.showCart} style={{ display: 'flex', fontSize: '14px', color: 'rgb(50,50,50)', justifyContent: 'center', alignItems: 'center', fontWeight: '400', verticalAlign: 'center', textAlign: 'center', margin: '10px', cursor: 'pointer'}}>
        <img  className={this.state.animationShake} src={cart} width="25px" height="25px" style={{ verticalAlign: 'center', marginRight: '5px' }} />{this.state.cart.length}
          </div>
        
          {!this.state.logged &&
          <div onClick={this.clickOnMyProfile}  style={{display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontSize: '12px', fontWeight: '400', verticalAlign: 'middle', margin: '10px', 
            height: '30px', borderRadius: '8px', backgroundColor: 'rgb(245,245,245)', cursor: 'pointer', width: '100px'}}>
          Login</div> }
          {this.state.logged &&
           <div onClick={this.clickOnMyProfile}  style={{display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontSize: '12px', fontWeight: '400', verticalAlign: 'middle', margin: '10px', 
            height: '30px', borderRadius: '8px', backgroundColor: 'rgb(245,245,245)', cursor: 'pointer', width: '100px'}}>
          {i18n.t('my.profile')}</div>}
         
        </div>
  
      

   <div style={{ display: "flex", flexDirection: "row", justifyContent: "left",
    alignItems: "stretch",
    flexWrap: 'wrap',
    flexShrink: 1}}>
        <div onClick={this.showHome} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: '600', verticalAlign: 'center', textAlign: 'center', margin: '2.5px', cursor: 'pointer'}}>
        <img src={logo} width="90px" height="45px" style={{ verticalAlign: 'center', marginRight: '15px' }} />
          </div>
        </div>
       
   </div>
     
      { (this.state.isReady && this.state.showHome) &&
       <Home />
      } 
      
      { (this.state.isReady && this.state.showRegione) &&
       <Regione code={this.state.regionCode} />
      } 

      { (this.state.isReady && this.state.showBusiness) &&
       <Azienda reloadCart={this.reloadCart} code={this.state.businessCode} />
      } 

      { (this.state.isReady && this.state.showCart) &&
       <Cart subscribe={this.openSubscribeNow} openLogin={this.clickOnMyProfile} logged={this.state.logged} reloadCart={this.reloadCart} totalCart={this.state.totalCart} products={this.state.cart} />
      } 
      { (this.state.isReady && this.state.showProfile) &&
      <Profile subscribe={this.openSubscribeNow} />
      }

      
      <br /><br /><br />

      <Footer />

      <Drawer
                    open={this.state.showLogin}
                        onRequestClose={this.closeLogin}
                        modalElementClass="modalMobileLogin2">
                            <div>
                            <div style={{textAlign: 'center', margin: '15px'}}>
            <span style={{color: 'rgb(50,50,50)', fontSize: 20, fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('login.now')} </span></div>
           
            <div  style={{marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: 'rgb(50,50,50)', fontWeight: 'normal', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgb(250,250,250)', border: 'none', outlineWidth: '0', fontSize: this.state.componentFontSize, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text"  
                name="email"
                value={this.state.email}
                placeholder={i18n.t('email')} 
                onChange={this.insertText}
                />
               </div>

               <div  style={{marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: 'rgb(50,50,50)', fontWeight: 'normal', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgb(250,250,250)', border: 'none', outlineWidth: '0', fontSize: this.state.componentFontSize, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="password"  
                name="password"
                value={this.state.password}
                placeholder={i18n.t('password')} 
                onChange={this.insertText}
                />
               </div>
               {this.state.errorField &&
            <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <br/><br/>
            <span style={{color: 'red', fontSize: 12, fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('error.field')}</span></div>
            } 
            {this.state.errorLogin &&
            <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <br/><br/>
            <span style={{color: 'red', fontSize: 12, fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('error.login')}</span></div>
            }
                    <div  style={{marginTop: '0px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: '#E82E15', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.loginNow}
            >{i18n.t('confirm')}</p>
                    </div>

                    <div onClick={this.showRecoverPassword} style={{ marginTop: '5px', textAlign: 'center', marginLeft: '20px', marginRight: '20px', marginBottom: '15px'}}><span style={{cursor: 'pointer', color: 'rgb(50,50,50)', fontSize: 12, fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('forgot.password')}</span></div>
   

                   
                    <div onClick={this.showRegister} style={{ marginTop: '10px', textAlign: 'center', marginLeft: '20px', marginRight: '20px', marginBottom: '10px'}}><span style={{cursor: 'pointer', color: 'black', fontSize: 14, fontWeight: '600', wordWrap: 'break-word'}}>{i18n.t('register.now')}</span></div>
   
                    </div>
                    </Drawer>

                    <Drawer
                    open={this.state.showRegister}
                        onRequestClose={this.closeLogin}
                        modalElementClass="modalMobileLogin2">
                            <div>
                            <div style={{textAlign: 'center', margin: '15px'}}>
            <span style={{color: 'rgb(50,50,50)', fontSize: 20, fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('register.now')}</span></div>
           
            <div  style={{marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: 'rgb(50,50,50)', fontWeight: 'normal', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgb(250,250,250)', border: 'none', outlineWidth: '0', fontSize: this.state.componentFontSize, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text"  
                name="name"
                value={this.state.name}
                placeholder={i18n.t('name')} 
                onChange={this.insertText}
                />
               </div>

               <div  style={{marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: 'rgb(50,50,50)', fontWeight: 'normal', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgb(250,250,250)', border: 'none', outlineWidth: '0', fontSize: this.state.componentFontSize, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text"  
                name="surname"
                value={this.state.surname}
                placeholder={i18n.t('surname')} 
                onChange={this.insertText}
                />
               </div>

               <div  style={{marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: 'rgb(50,50,50)', fontWeight: 'normal', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgb(250,250,250)', border: 'none', outlineWidth: '0', fontSize: this.state.componentFontSize, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text"  
                name="email"
                value={this.state.email}
                placeholder={i18n.t('email')} 
                onChange={this.insertText}
                />
               </div>

               <div  style={{marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: 'rgb(50,50,50)', fontWeight: 'normal', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgb(250,250,250)', border: 'none', outlineWidth: '0', fontSize: this.state.componentFontSize, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="password"  
                name="password"
                value={this.state.password}
                placeholder={i18n.t('password')} 
                onChange={this.insertText}
                />
               </div>
            
            
                <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
             <div style={{marginLeft: '25px',  height: '20px', borderRadius: '8px', width: '250px', color: 'black', fontSize: '12px', textAlign: 'left'}}>
             <input className={this.state.animationShake2} checked={this.state.ck2} onChange={this.checkboxAction} name="ck2" type="checkbox" /> 
             <a target="_blank" style={{ marginLeft: '5px', fontSize: '10px',  color: 'rgb(160,160,160)', textDecoration: 'none'}} href="https://italiagourmet.net/agreements/terms-and-conditions.pdf">
             {i18n.t('accept.terms')}
                 </a>
                
             </div>
            </div>

            <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
             <div style={{marginLeft: '25px',  height: '20px', borderRadius: '8px', width: '250px', color: 'black', fontSize: '12px', textAlign: 'left'}}>
             <input className={this.state.animationShake1} checked={this.state.ck1} onChange={this.checkboxAction} name="ck1" type="checkbox" /> 
             <a target="_blank" style={{ marginLeft: '5px', fontSize: '10px',  color: 'rgb(160,160,160)', textDecoration: 'none'}} href="https://italiagourmet.net/agreements/privacy-policy.pdf">
             {i18n.t('accept.privacy')}
                 </a>
                
             </div>
            </div>

            {this.state.errorField &&
            <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <br/><br/>
            <span style={{color: 'red', fontSize: 12, fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('error.field')}</span></div>
            }
            {this.state.errorMail &&
            <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <br/><br/>
            <span style={{color: 'red', fontSize: 12, fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('error.email')}</span></div>
            }
                          
                    <div  style={{marginTop: '0px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: '#E82E15', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.registerNow}
            >{i18n.t('confirm')}</p>
                    </div>
                    <div onClick={this.closeLogin} style={{  textAlign: 'center', marginLeft: '20px', marginRight: '20px', marginBottom: '10px'}}><span style={{cursor: 'pointer', color: 'black', fontSize: 12, fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('close')}</span></div>
   
                    </div>
                    </Drawer>

                    <Drawer
                    open={this.state.showRecoverPassword}
                        onRequestClose={this.closeLogin}
                        modalElementClass="modalMobileLogin2">
                          
                           

                          { !this.state.recoverSuccess && 
                            <div> <div style={{textAlign: 'center', margin: '15px'}}>

                             
            <div><span style={{color: 'rgb(50,50,50)', fontSize: 20, fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('forgot.password')}</span></div>
           
               <div  style={{marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: 'rgb(50,50,50)', fontWeight: 'normal', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgb(250,250,250)', border: 'none', outlineWidth: '0', fontSize: this.state.componentFontSize, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text"  
                name="email"
                value={this.state.email}
                placeholder={i18n.t('email')} 
                onChange={this.insertText}
                />
               </div>
               </div>
             


            {this.state.errorField &&
            <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <br/><br/>
            <span style={{color: 'red', fontSize: 12, fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('error.field')}</span></div>
            }
            {this.state.errorMail &&
            <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <br/><br/>
            <span style={{color: 'red', fontSize: 12, fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('error.email.recover')}</span></div>
            }
                          
                    <div  style={{marginTop: '0px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: '#E82E15', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.recoverPassword}
            >{i18n.t('confirm')}</p>
                    </div>
                    <div onClick={this.closeLogin} style={{  textAlign: 'center', marginLeft: '20px', marginRight: '20px', marginBottom: '10px'}}><span style={{cursor: 'pointer', color: 'black', fontSize: 12, fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('close')}</span></div>
   
                    </div>
                  }

{ this.state.recoverSuccess && 
                            <div> <div style={{textAlign: 'center', margin: '15px'}}>

                             
            <div><span style={{color: 'rgb(50,50,50)', fontSize: 20, fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('forgot.password')}</span></div>

            <br/><br/>
            <div><span style={{color: 'rgb(50,50,50)', fontSize: 16, fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('recover.passoword.success')}</span></div>
            <br/><br/>
              
                    <div onClick={this.closeLogin} style={{  textAlign: 'center', marginLeft: '20px', marginRight: '20px', marginBottom: '10px'}}><span style={{cursor: 'pointer', color: 'black', fontSize: 12, fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('close')}</span></div>
   
                    </div>
                    </div>
                  }
                    </Drawer>


                    <Drawer
                    open={this.state.openSubscription}
                    onRequestClose={this.closeSubscribeNow}
                        modalElementClass="modalMobileLogin2">
                         <div style={{textAlign: 'center', margin: '15px'}}>
                         <img src={rocket} width="150px" style={{ marginTop: '-70px' }} />
                         <div  style={{marginTop: '-5px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '14px', fontWeight: '400' }}>
                        {i18n.t('save.up.to')}
                        </div>
                        <div  style={{marginTop: '5px', color: '#E82E15', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '40px', fontWeight: '800' }}>
                        20%
                        </div>
                        <div  style={{marginTop: '5px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '14px', fontWeight: '400' }}>
                        {i18n.t('by.subscribe')}
                        </div>
                        <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {i18n.t('only')}
                        <div  style={{ marginLeft: '10px',  color: '#E82E15', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '30px', fontWeight: '800' }}>
                        24$
                        </div>
                        <div style={{ marginLeft: '10px' }}> {i18n.t('at.year')}</div>
                        </div>
                        <div  style={{marginTop: '30px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: 'rgb(50,50,50)', fontWeight: 'normal', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgb(250,250,250)', border: 'none', outlineWidth: '0', fontSize: this.state.componentFontSize, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text"  
                name="promocode"
                value={this.state.promocode}
                placeholder={i18n.t('invite.code')} 
                onChange={this.insertText}
                
                />
               </div>
                        <div  style={{marginTop: '0px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: '#E82E15', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.getPurchaseLink}
            >{i18n.t('continue')}</p>
                    </div>
                    </div>
                    </Drawer>

                    <Drawer
                    open={this.state.openLoader}
                        modalElementClass="modalLoader">
                         <div style={{textAlign: 'center', margin: '15px'}}>
            
                         <BoxLoading size="large" color="#E82E15" />
                    </div>
                    </Drawer>
    </div>
  );
    }
}

export default App;
