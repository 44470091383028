import React, { Component } from 'react';
import i18n from "i18next";
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';
import { BoxLoading } from 'react-loadingg';
import info from './media/info.png';
import products from './media/products.png';

export class Profile extends Component {


    constructor(props){
        super(props);
        this.state = { currentSection: 0, userInfo: null, orders: [], showEditPassword: false, showOrderDetails: false,
             newpassword: '', password: '', errorPassword: false, errorField: false, orderProducts: [], totalOrder: 0, currentOrderCode: ''  };
        
    }

    componentDidMount () {
      
        this.getProfileInfo();
        
    }

    closeOrderDetails = () => {
        this.setState({ showOrderDetails: false });
    }

    getProfileInfo = () => {
        var sessiontoken = localStorage.getItem('session_token');
        const requestOptions = {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify({
            token: sessiontoken
          })
          };
        
          const apiUrl = 'https://italiagourmet.cloud/api/public/v1/profile/details';
    
        fetch(apiUrl, requestOptions)
        .then(r => r.json())
        .then(r => {
          console.log(r);
          this.setState({ userInfo: r.user_info, orders: r.orders });
          
        }).catch(e => {
            
        });
      }

      showMyInfo = () => {
        this.setState({ currentSection: 0 });
      }

      showOrders = () => {
        this.setState({ currentSection: 1 });
      }

      logout = () => {
        var sessiontoken = localStorage.getItem('session_token');
        const requestOptions = {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify({
            token: sessiontoken
          })
          };
        
          const apiUrl = 'https://italiagourmet.cloud/api/public/v1/profile/logout';
    
        fetch(apiUrl, requestOptions)
        .then(r => r.json())
        .then(r => {
            localStorage.removeItem('session_token');
        window.location.reload();
          
        }).catch(e => {
            
        });
      }


      changePassword = () => {
        this.setState({ errorField: false, errorPassword: false });
        if(!(this.state.newpassword.length>5 && this.state.password.length>5)){
          this.setState({ errorField: true });
          return;
        }
      
        this.setState({ openLoader: true });
        var sessiontoken = localStorage.getItem('session_token');
        const requestOptions = {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify({
            token: sessiontoken,
            password: this.state.password,
            new_password: this.state.newpassword
          })
          };
        
          const apiUrl = 'https://italiagourmet.cloud/api/public/v1/profile/changepassword';
    
        fetch(apiUrl, requestOptions)
        .then(r => r.json())
        .then(r => {
         if(r.password == false){
          this.setState({ errorPassword: true });
         }else{
          this.closeLogin();
         } 
         this.setState({ openLoader: false });
          
        }).catch(e => {
          this.closeLogin();
          this.setState({ openLoader: false });
        });
      }

      showChangePassword = () => {
        this.setState({ showEditPassword: true, errorPassword: false, errorField: false });
      }


      closeLogin = () => {
        this.setState({ showEditPassword: false, errorPassword: false, errorField: false });
      }
   
      insertText = (e) => {
        this.setState({ errorField: false, errorPassword: false });
        var insert = String(e.target.value);   
        var fieldName = e.target.name;  
        this.setState({ [fieldName]: insert });     
      }


      getOrderDetails = (index) => {
        this.setState({ openLoader: true });
        var sessiontoken = localStorage.getItem('session_token');
        const requestOptions = {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify({
            token: sessiontoken,
            id: this.state.orders[index].id,
          })
          };
        
          const apiUrl = 'https://italiagourmet.cloud/api/public/v1/cart/order';
    
        fetch(apiUrl, requestOptions)
        .then(r => r.json())
        .then(r => {
           console.log(r);
         
         var totalOrder = this.totalOrderPrice(r.products);
         this.setState({ openLoader: false, showOrderDetails: true, orderProducts: r.products, currentOrderCode: this.state.orders[index].id, totalOrder: totalOrder  });
        }).catch(e => {
            this.setState({ openLoader: false });
          
        });
      }

      totalOrderPrice = (cart) => {
        var total = 0;
        for(var i=0; i<cart.length; i++){
          total += (cart[i].quantity*cart[i].price);
        }
        return total;
      }


      subscribeNow = () => {
        this.props.subscribe();
      }
  

    render() {
        return (
            <div style={{  }}>
            
            <br /> 

<div style={{ height: '80px', position: 'relative', backgroundColor: 'rgb(255,255,255)', display: 'flex', justifyContent: 'center', flexWrap: 'nowrap', overflowX: 'scroll', overflowY: 'hidden', flexDirection: 'row',scrollBehavior: 'smooth'}}>

<div onClick={() => this.showMyInfo()}  style={{  minWidth: '80px', height: '80px', position: 'relative', margin: '0px', cursor: 'pointer' }}>
<div style={{width: 80, height: 30, left: 0, top: 8, position: 'absolute'}}>
<img src={info} width="25px" height="25px" style={{ borderRadius: '0px'}} />
</div>
<div style={{width: 80, height: 40, left: 0, top: 42, position: 'absolute'}}>
{this.state.currentSection == 0 &&
<div style={{width: 80, height: 40, position: 'absolute', textAlign: 'center', color: 'rgb(50,50,50)', fontSize: 12,   fontWeight: '600', wordWrap: 'break-word'}}>{i18n.t('my.info')}</div>
}
{this.state.currentSection != 0 &&
<div style={{width: 80, height: 40, position: 'absolute', textAlign: 'center', color: 'rgb(50,50,50)', fontSize: 12,   fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('my.info')}</div>
}

</div>
{this.state.currentSection == 0 &&
<div style={{width: 50, height: 2, left: 15, top: 70, position: 'absolute', backgroundColor: 'rgb(50,50,50)'}}>
</div> }
</div>

<div onClick={() => this.showOrders()}  style={{  minWidth: '80px', height: '80px', position: 'relative', margin: '0px', cursor: 'pointer' }}>
<div style={{width: 80, height: 30, left: 0, top: 8, position: 'absolute'}}>
<img src={products} width="25px" height="25px" style={{ borderRadius: '0px'}} />
</div>
<div style={{width: 80, height: 40, left: 0, top: 42, position: 'absolute'}}>
{this.state.currentSection == 1 &&
<div style={{width: 80, height: 40, position: 'absolute', textAlign: 'center', color: 'rgb(50,50,50)', fontSize: 12,   fontWeight: '600', wordWrap: 'break-word'}}>{i18n.t('my.orders')}</div>
}
{this.state.currentSection != 1 &&
<div style={{width: 80, height: 40, position: 'absolute', textAlign: 'center', color: 'rgb(50,50,50)', fontSize: 12,   fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('my.orders')}</div>
}

</div>
{this.state.currentSection == 1 &&
<div style={{width: 50, height: 2, left: 15, top: 70, position: 'absolute', backgroundColor: 'rgb(50,50,50)'}}>
</div> }
</div>

</div>

{(this.state.userInfo != null && this.state.currentSection==0) &&
 <div style={{ display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "stretch",
  flexWrap: 'wrap',
  flexShrink: 1,}}>

{this.state.userInfo.subscription_plan == 0 && 

<div  style={{marginTop: '20px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: 'rgb(250,250,250)', fontSize: '14px', 
          textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(50,50,50)'}}
          onClick={this.subscribeNow}
          >{i18n.t('subscription.not.active')}</p>
                  </div>
  }

{this.state.userInfo.subscription_plan == 1 && 

<div  style={{marginTop: '20px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <p style={{ fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: 'rgb(250,250,250)', fontSize: '14px', 
          textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(50,50,50)'}}
         
          >{i18n.t('subscription.active')}</p>
                  </div>
  }

<div  style={{marginTop: '30px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '12px'}}>
{i18n.t('your.data')}
</div>
<div  style={{marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: 'rgb(50,50,50)', fontWeight: 'normal', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgb(250,250,250)', border: 'none', outlineWidth: '0', fontSize: this.state.componentFontSize, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text"  
                name="name"
                contentEditable="false"
                value={this.state.userInfo.first_name}
                placeholder={i18n.t('name')} 
                onChange={this.insertText}
                readOnly
                />
               </div>

               <div  style={{marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: 'rgb(50,50,50)', fontWeight: 'normal', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgb(250,250,250)', border: 'none', outlineWidth: '0', fontSize: this.state.componentFontSize, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text"  
                name="surname"
                contentEditable="false"
                value={this.state.userInfo.last_name}
                placeholder={i18n.t('surname')} 
                onChange={this.insertText}
                readOnly
                />
               </div>

               <div  style={{marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: 'rgb(50,50,50)', fontWeight: 'normal', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgb(250,250,250)', border: 'none', outlineWidth: '0', fontSize: this.state.componentFontSize, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text"  
                name="email"
                contentEditable="false"
                value={this.state.userInfo.email}
                placeholder={i18n.t('email')} 
                onChange={this.insertText}
                readOnly
                />
               </div>

               <div  style={{marginTop: '30px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: 'rgb(250,250,250)', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(50,50,50)'}}
            onClick={this.showChangePassword}
            >{i18n.t('change.password')}</p>
                    </div>
                    <div  style={{marginTop: '0px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: '#E82E15', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.logout}
            >Logout</p>
                    </div>
    </div>
    }


{(this.state.orders.length == 0 && this.state.currentSection==1) &&
<div><br/><br/>{i18n.t('no.orders')}</div>
}


{(this.state.orders.length != 0 && this.state.currentSection==1) &&
<div><br/><br/>
<div style={{ margin: 'auto', borderRadius: '8px', width: '300px', backgroundColor: 'rgb(250,250,250)', display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",}}>
    
  {
        this.state.orders.map((order, i) =>
<div key={i} onClick={() => this.getOrderDetails(i)}  style={{ cursor: 'pointer', borderRadius: '8px', width: '280px', minHeight: '70px', margin: '10px', backgroundColor: 'white', position: 'relative' }}>
        <div style={{ display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "stretch",
  flexWrap: 'wrap',
  flexShrink: 1,}}>

<div style={{display: "flex",
  flexDirection: "row", margin: '5px'}}>
 <div style={{width: 125,  marginLeft: '0px',  textAlign: 'left', color: 'rgb(50,50,50)', fontSize: 12,  fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('order.number')}</div>
 <div style={{width: 130,  marginLeft: '5px', textAlign: 'left', color: 'rgb(50,50,50)', fontSize: 12,  fontWeight: '500', wordWrap: 'break-word'}}>#{order.id}</div>
</div>

<div style={{display: "flex",
  flexDirection: "row", margin: '5px'}}>
 <div style={{width: 125,  marginLeft: '0px',  textAlign: 'left', color: 'rgb(50,50,50)', fontSize: 12,  fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('delivery.name')}</div>
 <div style={{width: 130,  marginLeft: '5px', textAlign: 'left', color: 'rgb(50,50,50)', fontSize: 12,  fontWeight: '500', wordWrap: 'break-word'}}>{order.customer_name}</div>
</div>

<div style={{display: "flex",
  flexDirection: "row", margin: '5px'}}>
 <div style={{width: 125,  marginLeft: '0px',  textAlign: 'left', color: 'rgb(50,50,50)', fontSize: 12,  fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('delivery.country')}</div>
 <div style={{width: 130,  marginLeft: '5px', textAlign: 'left', color: 'rgb(50,50,50)', fontSize: 12,  fontWeight: '500', wordWrap: 'break-word'}}>{order.customer_country}</div>
</div>

<div style={{display: "flex",
  flexDirection: "row", margin: '5px'}}>
 <div style={{width: 125,  marginLeft: '0px',  textAlign: 'left', color: 'rgb(50,50,50)', fontSize: 12,  fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('delivery.address')}</div>
 <div style={{width: 130,  marginLeft: '5px', textAlign: 'left', color: 'rgb(50,50,50)', fontSize: 12,  fontWeight: '500', wordWrap: 'break-word'}}>{order.customer_address}</div>
</div>
 </div>
            
</div>
        )
 }
  
  </div>
  </div>
}


                    <Drawer
                    open={this.state.openLoader}
                        modalElementClass="modalLoader">
                         <div style={{textAlign: 'center', margin: '15px'}}>
            
                         <BoxLoading size="large" color="#E82E15" />
                    </div>
                    </Drawer>

                    <Drawer
                    open={this.state.showEditPassword}
                        onRequestClose={this.closeLogin}
                        modalElementClass="modalMobileLogin2">
                            <div>
                            <div style={{textAlign: 'center', margin: '15px'}}>
            <span style={{color: 'rgb(50,50,50)', fontSize: 20, fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('change.password')}</span></div>
           
            <div  style={{marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: 'rgb(50,50,50)', fontWeight: 'normal', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgb(250,250,250)', border: 'none', outlineWidth: '0', fontSize: this.state.componentFontSize, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="password"  
                name="password"
                value={this.state.password}
                placeholder={i18n.t('password')} 
                onChange={this.insertText}
                />
               </div>

               <div  style={{marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: 'rgb(50,50,50)', fontWeight: 'normal', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgb(250,250,250)', border: 'none', outlineWidth: '0', fontSize: this.state.componentFontSize, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="password"  
                name="newpassword"
                value={this.state.newpassword}
                placeholder={i18n.t('new.password')} 
                onChange={this.insertText}
                />
               </div>
            
            

            {this.state.errorField &&
            <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <br/><br/>
            <span style={{color: 'red', fontSize: 12, fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('error.field')}</span></div>
            }
            {this.state.errorPassword &&
            <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <br/><br/>
            <span style={{color: 'red', fontSize: 12, fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('error.password')}</span></div>
            }
                          
                    <div  style={{marginTop: '0px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: '#E82E15', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.changePassword}
            >{i18n.t('confirm')}</p>
                    </div>
                    <div onClick={this.closeLogin} style={{  textAlign: 'center', marginLeft: '20px', marginRight: '20px', marginBottom: '10px'}}><span style={{cursor: 'pointer', color: 'black', fontSize: 12, fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('close')}</span></div>
   
                    </div>
                    </Drawer>

                    <Drawer
                    open={this.state.showOrderDetails}
                        onRequestClose={this.closeOrderDetails}
                        modalElementClass="modalMobileLogin2">
                            <div>
                            <div style={{textAlign: 'center', margin: '15px'}}>
            <span style={{color: 'rgb(50,50,50)', fontSize: 20, fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('order.number')} #{this.state.currentOrderCode}</span></div>
           

            {(this.state.orderProducts.length != 0) &&
<div><div style={{ display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "stretch",
  flexWrap: 'wrap',
  flexShrink: 1,}}>
   <div style={{margin: '10px'}}>
 <div style={{ margin: 'auto', borderRadius: '8px', width: '350px', backgroundColor: 'rgb(250,250,250)', display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",}}>
    
  {
        this.state.orderProducts.map((product, i) =>
<div key={i}  style={{ borderRadius: '8px', width: '330px', minHeight: '70px', margin: '10px', backgroundColor: 'white', position: 'relative' }}>
        <div style={{ display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "stretch",
  flexWrap: 'wrap',
  flexShrink: 1,}}>
<div style={{ width: 100, height: 70, left: 0, top: 0}}>
 <img src={product.image} width="100px" height="70" style={{ objectFit: 'cover', borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' }} />
 </div>
 <div style={{}}>
 <div style={{width: 140,  marginLeft: '5px', marginTop: '5px',   textAlign: 'left', color: 'rgb(50,50,50)', fontSize: 12,  fontWeight: '500', wordWrap: 'break-word'}}>{product.name}</div>
 <div style={{width: 140,  marginLeft: '5px', marginTop: '5px',   textAlign: 'left', color: 'rgb(50,50,50)', fontSize: 12,  fontWeight: '400', wordWrap: 'break-word'}}>{product.business_name}</div>
</div>
<div style={{ width: 80, backgroundColor: 'white' }}>
                <div style={{ marginTop: '10px', marginLeft: '5px', width: 80, display: 'flex', flexDirection: 'row'}}>
                <div style={{ marginLeft: '40px', marginRight: '20px', color: 'black', fontSize: '16px'}}>x{product.quantity}</div>
                </div> 
                <div style={{width: 80, top: 5, marginTop: '10px',  textAlign: 'center', color: 'rgb(50,50,50)', fontSize: 14,  fontWeight: '600', wordWrap: 'break-word'}}>{(Math.round(product.price*product.quantity) / 100).toFixed(2)}€</div>

                </div> 
 </div>
            

</div>
        )
 }
  
  </div>
  </div>
  <div style={{  margin: '10px', borderRadius: '8px',  width: '350px' }}>
    <br/>
  <div style={{ display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "stretch",
  flexWrap: 'wrap',
  flexShrink: 1,}}>
 <div style={{  margin: '10px',  textAlign: 'center', color: 'rgb(50,50,50)', fontSize: 16,  fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('subtotal')}</div>
 <div style={{ margin: '10px',  textAlign: 'center', color: 'rgb(50,50,50)', fontSize: 16,  fontWeight: '600', wordWrap: 'break-word'}}>{(Math.round(this.state.totalOrder) / 100).toFixed(2)}€</div>
 </div>
                    
  </div>
  
  </div>
 </div>
}


                    <div onClick={this.closeOrderDetails} style={{  textAlign: 'center', marginLeft: '20px', marginRight: '20px', marginBottom: '10px'}}><span style={{cursor: 'pointer', color: 'black', fontSize: 12, fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('close')}</span></div>
   
                    </div>
                    </Drawer>

<br/><br/><br/>
            </div>
        )
    }
}

export default Profile
